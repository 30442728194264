<template>
  <div class="container-conteudo">
    <div class="conteudo-interno">
      <div class="nova-comunicacao-container">
        <h2>Adicionar Usuário</h2>
        <formulario-usuarios />
      </div>
    </div>
  </div>
</template>

<script>
const FormularioUsuarios = () => import("@/components/elementos/paginas/FormularioUsuarios.vue");

export default {
  components: {
    FormularioUsuarios
  }
}
</script>